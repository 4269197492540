import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Home from './routes/home';
import Membership from './routes/membership';

import liff from '@line/liff';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const HOME_LIFF_ID = process.env.REACT_APP_HOME_LIFF_ID || '';
const MEMBERSHIP_LIFF_ID = process.env.REACT_APP_MEMBERSHIP_LIFF_ID || '';

const liffId = location.pathname.includes('membership')
  ? MEMBERSHIP_LIFF_ID
  : HOME_LIFF_ID;

const theme = createTheme({
  palette: {
    primary: {
      main: '#1d6ad4',
    },
  },
});

liff
  .init({
    liffId,
  })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ja}
              dateFormats={{ monthAndYear: 'yyyy年M月' }}
              localeText={{
                cancelButtonLabel: 'キャンセル',
                okButtonLabel: '選択',
              }}
            >
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="membership" element={<Membership />} />
                </Routes>
              </BrowserRouter>
            </LocalizationProvider>
          </Provider>
        </ThemeProvider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });
