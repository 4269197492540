import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import liff from '@line/liff';
import { userId } from '../utils/liff';

import QRCode from 'qrcode.react';
import moment from 'moment';

type MembershipDetailProps = {
  contact: any;
};

const handleClose = () => {
  liff.closeWindow();
};

const MembershipDetail: React.FC<MembershipDetailProps> = ({ contact }) => {
  let name = '';
  let banquetDay = '';
  try {
    const lastnameProp = contact.properties['lastname'];
    const firstnameProp = contact.properties['firstname'];

    if (lastnameProp) {
      name = lastnameProp.value;
    }
    if (firstnameProp) {
      name = name + ' ' + firstnameProp.value;
    }

    const banquetDayProp = contact.properties['banquet_day__o'];
    if (banquetDayProp) {
      banquetDay = moment(Number(banquetDayProp.value)).format('YYYY年M月D日');
    }
  } catch (e) {
    console.error(e);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            margin: '1em',
          }}
        >
          受付でこちらの画面をご提示ください
        </div>
        <QRCode
          id={`qrcode-detail-${userId()}`}
          value={String(userId()).substring(0, 20)}
          size={350}
          includeMargin={true}
          style={{
            maxHeight: 200,
            maxWidth: 200,
          }}
        />
      </div>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          border: '1px solid rgb(0 0 0 / 12%)',
          margin: '1em 1em 1em 1em',
          padding: '1em',
        }}
      >
        {/*<div>{`${name} 様（会員No. ${contact.vid}）`}</div>*/}
        <div>
          <span>会員番号：</span>
          <span>　{contact.vid}</span>
        </div>
        <div>
          <span>お名前　：</span>
          <span>　{name}</span>
        </div>
        <div>
          <span>宴席日　：</span>
          <span>　{banquetDay}</span>
        </div>
      </Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '.8em',
        }}
      >
        MASUDA PLANNING Co.,Ltd.
      </div>
      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: 'auto', bottom: 0, backgroundColor: '#396079' }}
      >
        <Toolbar>
          <Button
            color="inherit"
            aria-label="open drawer"
            sx={{ width: '100%' }}
            onClick={handleClose}
          >
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>閉じる</span>
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MembershipDetail;
