import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  isLoaded,
  retrieveContact,
  selectContact,
} from '../features/profile/membershipSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { userId } from '../utils/liff';
import liff from '@line/liff';
import MembershipDetail from '../components/membershipDetail';

export default function Membership() {
  const dispatch = useAppDispatch();
  const contact: any = useAppSelector(selectContact);
  const loaded = useAppSelector(isLoaded);

  useEffect(() => {
    document.title = 'デジタル会員証';

    if (!loaded) {
      dispatch(
        retrieveContact({
          userId: userId(),
        }),
      );
    }
  }, [loaded]);

  const [open, setOpen] = React.useState(!userId());
  const handleClose = () => {
    setOpen(false);
    liff.closeWindow();
  };

  const status = contact
    ? contact.properties['membership_status__o']
      ? contact.properties['membership_status__o'].value
      : ''
    : '';

  return (
    <span>
      <AppBar
        position="static"
        elevation={0}
        style={{
          alignItems: 'center',
          backgroundColor:
            status === 'Platinum'
              ? '#E89A3C'
              : status === 'Diamond'
              ? '#7f8282'
              : '#645580',
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div">
            {status}
          </Typography>
        </Toolbar>
      </AppBar>
      {!contact ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 8 }}>
          <CircularProgress />
        </Box>
      ) : (
        <MembershipDetail contact={contact} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'お知らせ'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            スマートフォン版LINEでご利用になれます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
