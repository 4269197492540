import * as React from 'react';

export default function Home() {
  return (
    <div
      style={{
        padding: '1em',
        color: '#3e4651',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      LITTLE HELP APPS HOME
    </div>
  );
}
