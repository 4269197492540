import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL || '';
const CONTACT_API_KEY = process.env.REACT_APP_CONTACT_API_KEY || '';

export interface MembershipState {
  contact: any;
  status: 'idle' | 'loading' | 'failed';
  loaded: boolean;
}

const initialState: MembershipState = {
  contact: null,
  status: 'idle',
  loaded: false,
};

export const retrieveContact = createAsyncThunk(
  'retrieveContact',
  async (params: any) => {
    const response = await axios.get(
      `${API_SERVER_URL}/line/v1/contact/${params.userId}?apikey=${CONTACT_API_KEY}`,
    );
    return response.data;
  },
);

export const membershipSlice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    hello: (state) => {
      console.log(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(retrieveContact.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(retrieveContact.fulfilled, (state, action) => {
        state.status = 'idle';
        state.contact = action.payload;
        state.loaded = true;
      });
  },
});

export const selectContact = (state: RootState) => state.membership.contact;
export const isLoaded = (state: RootState) => state.membership.loaded;

export default membershipSlice.reducer;
